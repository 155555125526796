* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: normal;
  text-decoration: none;
  border: none;
  background: transparent;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Add this to hide WebKit scrollbars globally */
*::-webkit-scrollbar {
  display: none;
}

body {
  font-family: 'Inter', sans-serif;
  background-color: #000000;
}

input {
  outline: none;
}

code[class*='language-'],
pre[class*='language-'] {
  background: rgb(26, 26, 26) !important;
  margin: 0 !important;
  padding: 0 !important;
  z-index: 0 !important;
}

code[class*='code-display'],
pre[class*='code-display'] {
  background: rgb(26, 26, 26) !important;
  margin: 0 !important;
  padding: 0 !important;
  z-index: 0 !important;
  font-size: 12px !important;
  font-family: 'JetBrains Mono', monospace !important;
  font-weight: 300 !important;
  line-height: normal !important;
}

button {
  text-align: left;
}

.slide-panel {
  transform: translateX(100%);
  opacity: 1;
  animation: slideInPanel 300ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
}

.slide-panel-exit {
  transform: translateX(0);
  opacity: 1;
  animation: slideOutPanel 300ms cubic-bezier(0.3, 1, 0.8, 1) forwards;
}

.fade-background {
  background: transparent;
  animation: fadeBackground 300ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
}

.fade-background-exit {
  background: #00000099;
  animation: fadeBackgroundExit 300ms cubic-bezier(0.3, 1, 0.8, 1) forwards;
}

@keyframes slideInPanel {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutPanel {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes fadeBackground {
  from {
    background: transparent;
  }
  to {
    background: #00000099;
  }
}

@keyframes fadeBackgroundExit {
  from {
    background: #00000099;
  }
  to {
    background: transparent;
  }
}

.slide-popup-bottom {
  transform: translate(-50%, 8px);
  opacity: 0;
  animation: slideInPopupBottom 300ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
}

@keyframes slideInPopupBottom {
  from {
    transform: translate(-50%, 8px);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}
